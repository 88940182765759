<template>
  <searchable-table
    :items="[]"
    :fields="fields"
  />
  <!-- <b-alert variant="primary" show>
    <div class="alert-body">Online sistem yakında eklecenektir...</div>
  </b-alert> -->
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'field1', label: 'Tarih' },
        { key: 'field3', label: 'Alacak' },
        { key: 'field2', label: 'Ödeme Tipi' },
        { key: 'field4', label: 'Borç' },
        { key: 'field5', label: 'Bakiye' },
      ],
    }
  },
}
</script>

<style></style>
